import React, {useEffect, useState} from 'react';
import config from '../../config';
import _,{map} from 'lodash';
import StdQuery from "../../common/StdQuery";
import gql from "graphql-tag";
import Auth from "../../Auth";
import {Col, Label, Row, Table} from "reactstrap";
import Select from "react-select";
import queryString from 'qs';


const query = gql`
query getTemplates($filter:TemplateFilterObject)
{
    templates(filter: $filter)
    {
        id
        name
        category
        file
        {
            id
            filename
            downloadUrl
        }
    }
}`;

const generateUrl = (category,templateFileId,metadata,filename)=>
{
    return `${config.BACKEND_URI}template/${category}/${templateFileId}/
${filename}?token=${Auth.getToken()}&${queryString.stringify(metadata)}`;
};



export default ({quote,order})=>
{


    let defaultCategory;
    let url;
    if(quote)
    {
        defaultCategory = "quote";
        let fileName
        const projectName = quote?.project?.name
        if (projectName) {
            const encodedFilename = encodeURIComponent(projectName.replaceAll('/', ' '));
            fileName = `${quote.number}-${encodedFilename}`;
        } else {
            fileName = `${quote.number}`;
        }
        url = file=>generateUrl(defaultCategory,file.id,{quoteId:quote.id},`${fileName}.docx`);
    }
    else if(order)
    {
        defaultCategory = "order";
        url = file=>generateUrl(defaultCategory,file.id,{orderId:order.id},`${order.number}.docx`);
    }
    else
    {
        return <p style={{color:"red"}}>Failure. Missing order or quote.</p>
    }
    const [filter,setFilter] = useState({category:defaultCategory,tenantId:Auth.getTenant()});
    const [tenants,setTenants] = useState([]);
    useEffect(() =>
    {
        fetch(config.BACKEND_URI + "tenants").then(res => res.json()).then(
        tenants => setTenants(map(tenants, tenant => ({id: tenant.name, name: tenant.name}))));
    }, []);
    return <div>
        <Row>
            <Col sm={3}>
                <Label>Filter by Tenant</Label>
                <Select defaultValue={{value:filter.tenantId,label:filter.tenantId}}
                        onChange={val=>setFilter(f=>
                {
                    if(val.value === "")
                    {
                        return ({..._.omit(f,"tenantId")})
                    }
                    else
                    {
                        return ({...f,tenantId:val.value})
                    }

                })}
                        options={tenants.map(({id,name})=>({value:id,label:name})).concat({value:"",label:"All"})} />
            </Col>
        </Row>
        <Row>
            <Col sm={3}>
                <StdQuery query={query} variables={{filter}}>
                    {data =>
                    {
                        if(data.templates && data.templates.length > 0)
                        {

                            return <Table bordered striped>
                                <tbody>
                                {_.map(data.templates,template=>
                                <tr key={template.id}>
                                    <td>
                                        <a href={url(template.file)}>
                                            {template.name}
                                        </a>
                                    </td>
                                </tr>
                                )}
                                </tbody>

                            </Table>
                        }
                        else
                        {
                            return `Could not find any templates..`;
                        }
                    }
                    }
                </StdQuery>
            </Col>
        </Row>

    </div>





}
