import React, {Component} from "react";
import {Badge, Button, Card, CardBody, CardTitle, Col, Input, Label, Row} from "reactstrap";
import gql from "graphql-tag";
import {graphql,withApollo} from "@apollo/client/react/hoc";
import _, {filter, omit} from 'lodash';
import StdQuery from "../../../common/StdQuery";
import ArticleFragment from '../../../common/article/graphql/ArticleFragment.graphql';
import ArticleRowFragment from '../../../common/graphql/ArticleRowFragment.graphql';
import FormRenderer from "../../../common/FormRenderer";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import GetTaskTypes from '../../graphql/GetTaskTypes.graphql';
import nanoid from 'nanoid';
import Trash from "../../../common/icons/Trash";
import Dot from "../../../common/icons/Dot";
import Up from "../../../common/icons/Up";
import DoubleUp from "../../../common/icons/DoubleUp";
import SurfaceTreatmentTag from "../../../common/SurfaceTreatmentTag";
import NonPaginatedDataGrid from "../../../common/data-grid/NonPaginatedDataGrid";
import ColumnId from "../../../common/data-grid/columns/ColumnId";
import NotificationPopup from "../../../common/lib/NotificationPopup";
import {FORMAT_DATE, formatToSwedishTime} from "../../../UTIL";

const TABLE_KEY = "TABLE_KEY_SCHEDULER_ARTICLE"

const getPlannableArticles = gql`
    query schedulerArticleRows($orderId:ID,$purchaseOrderId:ID)
    {
        schedulerArticleRows(orderId:$orderId,purchaseOrderId:$purchaseOrderId)
        {
            ...ArticleRowFragment
            purchaseOrder
            {
                number
                id
                order
                {
                    tenantId
                    id
                    number
                    preferredDeliveryDate
                    assignee
                    {
                        displayName
                    }
                    prioritisation {
                        id
                        level
                        name
                    }
                }
            }
            order
            {
                tenantId
                id
                number
                preferredDeliveryDate
                assignee
                {
                    displayName
                }
                prioritisation {
                    id
                    level
                    name
                }
            }
        }
    }
    ${ArticleRowFragment}
    ${ArticleFragment}
`;

const createTasks = gql`
    mutation createTasks($tasks:[SchedulerTaskInputObject],$articleRowId:ID!)
    {
        schedulerCreateTasks(tasks:$tasks,articleRowId:$articleRowId)
        {
            id
            
        }
    }
`;

class ScheduleArticle extends Component {


    initialState = {
        articleRow: null,
        tasks:[],
        plannedProductionDate: null

    };
    constructor(props)
    {
        super(props);
        this.state = this.initialState
    }

    columns = () => {
        return [
            {
                field: 'article.artNo', headerName: "ArtNo",
                renderCell: (params) => {
                    const original = params.row
                    return <span className='link'
                                 onClick={() => {
                                     this.setState({articleRow: original});

                                     if (this.props.orderId || original.orderId) {
                                         this.setState({prioritisation: original.order.prioritisation});
                                     } else if (this.props.purchaseOrderId || original.purchaseOrderId) {
                                         this.setState({prioritisation: original.purchaseOrder.order.prioritisation});
                                     }
                                 }}>
                        {params.value}
                    </span>
                }
            },
            {field: 'quantity', filterable: false},
            {
                field: 'order',
                filterable: false,
                sortable: false,
                valueGetter: (params) => {
                    return params.row?.order || params.row?.purchaseOrder?.order;
                },
                renderCell: (params) => {
                    const order = params.value
                    return `${order.tenantId} - ${order.number}`
                }
            },
            {
                field: 'assignee',
                filterable: false,
                valueGetter: (params) => {
                    return params.row?.order?.assignee?.displayName || params.row?.purchaseOrder?.order?.assignee?.displayName;
                },
            },
            {
                field: 'article',
                headerName: 'View article',
                filterable: false,
                sortable: false,
                renderCell: (params) => {
                    const article = params.row?.article;
                    if (article?.spiralStair) {
                        const id = `spiralStair${article.artNo}`;
                        return <span>
                            <img alt={"favicon"} src="/favicon.png"/>
                            <Link id={id} to={`/spiralStairDetail/${article.spiralStair.id}`}
                                  target="_blank">View</Link>
                            </span>
                    } else if (article) {
                        return article.artNo;
                    } else {
                        return "Dont schedule me..";
                    }
                }
            },

            ColumnId(),
        ]
    }

    articleRowList()
    {
        if(this.props.articleRows){
            return <NonPaginatedDataGrid
                definition={{
                    tableKey: {TABLE_KEY},
                    columns: this.columns(),
                    disableTooBar: true,
                }}
                data={this.props.articleRows}/>
        }

        return <StdQuery query={getPlannableArticles}
                         variables={{orderId: this.props.orderId, purchaseOrderId: this.props.purchaseOrderId}}>
            {(data) => {
                if (this.props.setEstimationListEmpty) {
                    this.props.setEstimationListEmpty(data.schedulerArticleRows.length === 0);
                }

                return <NonPaginatedDataGrid
                    definition={{
                        tableKey: {TABLE_KEY},
                        columns: this.columns(),
                        disableTooBar: true,
                    }}
                    data={data.schedulerArticleRows}/>
            }}
        </StdQuery>
    }

    finishEstimation = async() =>
    {
        if(this.state.tasks.length < 1)
        {
            alert(`No tasks estimated. Please estimate tasks.`);
            return;
        }
        try
        {
            const order = this.state.articleRow.order || this.state.articleRow.purchaseOrder.order;
            const plannedProductionDate = this.state.plannedProductionDate || order.preferredDeliveryDate;
            await this.props.createTasks({variables:
                {
                    tasks:this.state.tasks.map(task => ({...omit(task,["id"]),plannedProductionDate})),
                    articleRowId:this.state.articleRow.id
                },refetchQueries:["schedulerArticleRows", "getEvents"]});
            NotificationPopup.success(`Create task success.`)
            this.setState(this.initialState);
        }
        catch(e)
        {
            alert(`Failed to create tasks. ${e.message}`);
        }

    };

    estimationStep(taskTypes)
    {
        const order = this.state.articleRow.order || this.state.articleRow.purchaseOrder.order;
        const preferredDeliveryDate = order.preferredDeliveryDate;
        return (
        <div>
            <Row>
                <Col>
                    <ul>
                        {this.state.tasks.map((task ,index)=>
                        <li key={index}>
                            {_.find(taskTypes,{id:task.taskTypeId}).name} Duration: {task.duration} min
                            <span className={"link"} onClick={()=>{
                                console.log(`removing ${task.id}`);
                                console.log(this.state.tasks);
                                this.setState({tasks:filter(this.state.tasks,t=>t.id !== task.id)})
                            }}><Trash/></span>
                        </li>)}
                    </ul>
                    <FormRenderer submitButtonText={`Add task`}  disableTimeout={true}
                                  formDefinition={[
                                      {name: "taskTypeId", type: "select", options: taskTypes, required: true},
                                      {name: "duration", type: "number", required: true}]}
                                  onSubmit={({object})=>
                    {
                        this.setState({tasks:this.state.tasks.concat([{id:nanoid(),...object}])})

                    }}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Planned production date for all tasks</Label>
                    <br/>
                    <small>Order preferred delivery date: {formatToSwedishTime(preferredDeliveryDate, FORMAT_DATE)}</small>
                    <Input type="date" value={this.state.plannedProductionDate || preferredDeliveryDate} onChange={(e)=>
                    {
                        this.setState({plannedProductionDate:e.target.value});
                    }} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button style={{marginTop:"25px"}} color="primary" onClick={()=>this.finishEstimation()}>
                        Finish estimating article row
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button color="warning" onClick={()=>this.setState(this.initialState)}>
                        Go Back
                    </Button>
                </Col>
            </Row>
        </div>
        );
    }


    scheduleArticleCard1(schedulerTaskTypes)
    {
        const spiralStair = _.get(this.state.articleRow,"article.spiralStair");
        console.log("spiralStair", spiralStair)
        // let surfaceTreatment = _.get(this.state.articleRow,"article.spiralStair.surfaceTreatment");
        // if(surfaceTreatment)
        // {
        //     //SurfaceTreatmentTypeColor
        //     const surfaceType = _.find(SurfaceTreatmentTypes,s=>s.id===surfaceTreatment);
        //     surfaceTreatment = <Badge style={{fontSize:"25px"}} color={surfaceType.color}>{surfaceType.name}</Badge>;
        // }
        let sign = "";

        switch (this.state.prioritisation?.level){
            case 0: sign = <Dot/>; break;
            case 1: sign = <Up/>; break;
            case 2: sign = <DoubleUp/>; break;
            default: break;
        }
        return <Card body>
            <CardTitle>
                <h2>
                    {this.state.articleRow.name} - {this.state.articleRow.article.artNo}<br/>
                    {sign} <Badge>{this.state.prioritisation?.name}</Badge>
                </h2>
            </CardTitle>
            <CardBody>
                {spiralStair && <SurfaceTreatmentTag spiralStair={spiralStair}/>}
                <div>
                    {this.estimationStep(schedulerTaskTypes)}
                </div>
            </CardBody>
        </Card>

    }


    render()
    {
        if(this.state.articleRow)
        {
            return <StdQuery query={GetTaskTypes}>
                {data => {
                    return this.scheduleArticleCard1(data.schedulerTaskTypes);
                }}
            </StdQuery>
        }
        else
        {
            return <div style={{marginBottom: "10px"}}>
                <h3>Article row list</h3>
                {this.articleRowList()}
            </div>
        }
    }
}

ScheduleArticle.propTypes =
{
    orderId:PropTypes.number,
    purchaseOrderId:PropTypes.number,
    articleRows: PropTypes.object
};

export default withApollo(graphql(createTasks,{name:"createTasks"})(ScheduleArticle));
